<svg
  viewBox="0 0 2880 750"
  xmlns="http://www.w3.org/2000/svg"
  xml:space="preserve"
  fill-rule="evenodd"
  clip-rule="evenodd"
  stroke-linejoin="round"
  stroke-miterlimit="2"
  ><g transform="scale(1.61164 .73457)"
    ><path fill="none" d="M0 0h1787v1021H0z" /><clipPath id="footerBackground-a"
      ><path d="M0 0h1787v1021H0z" /></clipPath
    ><g clip-path="url(#footerBackground-a)"
      ><ellipse
        cx="943.5"
        cy="945.772"
        rx="1030.5"
        ry="320.228"
        fill="url(#footerBackground-b)"
        transform="matrix(.61206 0 0 1.72693 -84.499 -950.205)"
      /><ellipse
        cx="943.5"
        cy="945.772"
        rx="1030.5"
        ry="320.228"
        fill="url(#footerBackground-c)"
        transform="matrix(.51924 -.04418 .0305 1.72563 895.333 -831.674)"
      /><path fill="#9a7c5c" d="M280.51 323.467h14.594v32.02h-14.595z" /><path
        d="m287.163 175.61-19.746 61.214 6.439-.941-16.74 54.622 14.594-6.593-21.034 71.574s37.658-32.512 74.26-10.359c-3.635-16.403-19.316-62.156-19.316-62.156h10.732l-15.883-46.147 5.151-.941-18.457-60.273Z"
        fill="#6ba82f"
      /><path fill="#9a7c5c" d="M558.988 149.224h9.983v22.44h-9.983z" /><path
        d="m563.539 45.604-13.506 42.9 4.404-.66-11.451 38.28 9.983-4.62-14.387 50.16s25.758-22.786 50.795-7.26c-2.487-11.496-13.213-43.56-13.213-43.56h7.34l-10.863-32.34 3.523-.66-12.625-42.24Z"
        fill="#558e1d"
      /><g
        ><path
          fill="#9a7c5c"
          d="M738.545 373.299h11.313v27.186h-11.313z"
        /><path
          d="m743.702 247.762-15.305 51.974 4.99-.8-12.976 46.377 11.313-5.597-16.304 60.769s29.19-27.605 57.563-8.796c-2.819-13.926-14.973-52.773-14.973-52.773h8.318l-12.311-39.18 3.993-.8-14.308-51.174Z"
          fill="#509a07"
        /></g
      ><g
        ><path fill="#9a7c5c" d="M873.48 295.06h10.88v26.149h-10.88z" /><path
          d="m878.44 174.316-14.721 49.99 4.8-.77-12.481 44.607 10.88-5.384-15.68 58.45s28.075-26.551 55.364-8.46c-2.71-13.395-14.401-50.759-14.401-50.759h8l-11.84-37.684 3.84-.77-13.761-49.22Z"
          fill="#509a07"
        /></g
      ></g
    ></g
  ><defs
    ><linearGradient
      id="footerBackground-b"
      x1="0"
      y1="0"
      x2="1"
      y2="0"
      gradientUnits="userSpaceOnUse"
      gradientTransform="matrix(-1356.43 -207.322 266.622 -1054.74 1472.18 1001.13)"
      ><stop offset="0" stop-color="#6ba82f" /><stop
        offset="1"
        stop-color="#87cc5b"
      /></linearGradient
    ><linearGradient
      id="footerBackground-c"
      x1="0"
      y1="0"
      x2="1"
      y2="0"
      gradientUnits="userSpaceOnUse"
      gradientTransform="matrix(-1780.39 268.809 -407.185 -1175.35 1693.39 727.235)"
      ><stop offset="0" stop-color="#86e74a" /><stop
        offset="1"
        stop-color="#6ba82f"
      /></linearGradient
    ></defs
  ></svg
>
