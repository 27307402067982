<svg viewBox="0 0 50 50" xmlns="http://www.w3.org/2000/svg"
  ><g fill="none" fill-rule="evenodd"
    ><path
      d="M29.591 5.93c-8.87-2.021-13.331-.52-16.697 2.314C9.528 11.077 6.8 17.47 8.54 28.865 10.28 40.26 17.819 45 23.705 45c5.886 0 12.009-2.816 15.35-8.516 3.343-5.7 2.929-10.585 2.929-17.813 0-7.227-3.523-10.72-12.393-12.741Z"
      fill="#5561E6"
    /><circle fill="#6773F6" cx="25" cy="25" r="11" /><path
      d="M33.905 25.766c-9.893-.296-16.552-.408-17.79-.374-.002.555-.002 1.556 0 3.001l7.97 6.522a1.5 1.5 0 0 0 1.873.023c4.118-3.211 6.74-5.278 7.858-6.197.043-.901.073-1.893.089-2.975Z"
      stroke="#FFF"
      fill="#8889B9"
    /><path
      d="M25.635 20.56a1.5 1.5 0 0 0-1.27 0l-7.396 3.457a1.5 1.5 0 0 0-.247 2.572l7.396 5.377a1.5 1.5 0 0 0 1.764 0l7.396-5.377a1.5 1.5 0 0 0-.247-2.572l-7.396-3.457Z"
      stroke="#FFF"
      fill="#8889B9"
    /><rect
      stroke="#FFF"
      stroke-width=".5"
      fill="#4E5280"
      x="23.75"
      y="14.25"
      width="2.5"
      height="12.5"
      rx="1.25"
    /><circle fill="#FF6F82" cx="25" cy="16" r="3" /><ellipse
      fill="#4f5280"
      transform="rotate(-8 19.5 25)"
      cx="19.5"
      cy="25"
      rx="1.5"
      ry="1"
    /><path fill="#FFA156" d="m5.023 31.046 3.408 4.431-4.908.954z" /><path
      fill="#EF629D"
      d="m41.023 22.046 3.408 4.431-4.908.954z"
    /><path fill="#FFC94F" d="m43.626 9.803.843 3.246-2.934-.624z" /></g
  ></svg
>
