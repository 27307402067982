// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import App from "./App.svelte";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyDUCPoFPBqjjREwZ0ev6RAjATJg9CSDGjs",
  authDomain: "steamius-website.firebaseapp.com",
  projectId: "steamius-website",
  storageBucket: "steamius-website.appspot.com",
  messagingSenderId: "592459263968",
  appId: "1:592459263968:web:0bb312eaf505a9585dc585",
  measurementId: "G-ERJH7E3HZQ",
};

// Initialize Firebase
const firebaseApp = initializeApp(firebaseConfig);
const analytics = getAnalytics(firebaseApp);

const app = new App({
  target: document.body,
  // props: {
  // 	name: 'world'
  // }
});

export default app;
