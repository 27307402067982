<script>
  import { Link } from "svelte-navigator";
  import {
    AiFeatureIcon,
    BalloonDecor,
    ButtonGreen,
    ButtonRoundYellow,
    CloudDecor,
    EarthPlanetDecor,
    EducationFeatureIcon,
    Footer,
    GameFeatureIcon,
    InteractiveFeatureIcon,
    Header,
    PuzzleFeatureIcon,
    ReportFeatureIcon,
  } from "../components";

  let activeSlide = 0;

  /**
   * Note:
   * Don't do more than 5 announcements as it will currently overflow on 320px mobile devices.
   */
  let slides = [
    "We are currently testing our new Singapore Math curriculum with our AI and adaptive learning algorithms.",
    "STEAMius is working with early adopters to fine-tune our upcoming math application for iOS, Android, and the web.",
  ];

  function handleSlide(n) {
    let slide = n > slides.length - 1 ? 0 : n < 0 ? slides.length - 1 : n;
    activeSlide = slide;
  }
</script>

<svelte:head>
  <title>STEAM for Kids - Fun and Challenging Early Education</title>
</svelte:head>

<!-- TODO: large don't exist. -->
<Header title="" color="blue" height="large">
  <div class="hero-content">
    <div class="hero-content-left">
      <p class="hero-content-title">Early Education</p>
      <p class="hero-content-text">
        Learn about our planet's animals and their habitats with our unique
        interactive approach developed for a new generation of young learners.
      </p>
      <!-- <Link to="/invest"><ButtonGreen label="Investors" width={220} /></Link> -->
    </div>
    <div class="hero-image-container">
      <div class="hero-image-wrapper">
        <!-- <img class="hero-image image" src="/assets/images/toucan.webp" alt="" /> -->
      </div>
    </div>
  </div>
</Header>

<main class="main boxed">
  <!-- Announcements -->
  <section class="section-announcements">
    <h2 class="section-title">Latest Announcements</h2>
    <div class="slider-slide">
      <div
        class="slider-button-left"
        on:click={() => handleSlide(activeSlide - 1)}
      >
        <ButtonRoundYellow direction="left" />
      </div>
      <p class="slider-text">{slides[activeSlide]}</p>
      <div
        class="slider-button-right"
        on:click={() => handleSlide(activeSlide + 1)}
      >
        <ButtonRoundYellow direction="right" />
      </div>
      <div class="slider-pagination">
        {#each slides as _, i}
          <span
            class="slider-pagination-circle"
            class:slider-pagination-circle--active={activeSlide === i}
          />
        {/each}
      </div>
    </div>
  </section>

  <!-- Features -->
  <section class="section-features">
    <div>
      <h2 class="section-title">It's time learning is fun!</h2>
      <p class="section-text">
        Our math curriculum is redefining early education as we are based on the
        latest scientific research and with the use of artificial intelligence,
        we can build and adjust your child's learning path and skill level: this
        is what we call adaptive learning.
      </p>
    </div>
    <!-- Feature icons: middle -->
    <div class="feature-icons">
      <div class="feature-icon">
        <ReportFeatureIcon />
        <p class="feature-icon-text">Tracking Reports for Parents</p>
      </div>
      <div class="feature-icon">
        <PuzzleFeatureIcon />
        <p class="feature-icon-text">Gamification</p>
      </div>
      <div class="feature-icon">
        <AiFeatureIcon />
        <p class="feature-icon-text">Adaptive Learning Powered by AI</p>
      </div>
      <div class="feature-icon">
        <GameFeatureIcon />
        <p class="feature-icon-text">Fun Math Games</p>
      </div>
      <div class="feature-icon">
        <InteractiveFeatureIcon />
        <p class="feature-icon-text">Interactive Lessons</p>
      </div>
      <div class="feature-icon">
        <EducationFeatureIcon />
        <p class="feature-icon-text">Ages 2-8</p>
      </div>
    </div>
    <img class="features-image image" src="/assets/images/fun.webp" alt="" />
  </section>

  <!-- Different ways to learn -->
  <section class="section-different-ways">
    <img
      class="section-different-ways-image-1 image"
      src="/assets/images/classroom.webp"
      alt=""
    />
    <img
      class="section-different-ways-image-2 image"
      src="/assets/images/workbook.webp"
      alt=""
    />
    <div class="section-different-ways-image-3-group">
      <img
        class="section-different-ways-image-3 image"
        src="/assets/images/game.webp"
        alt=""
      />
      <img
        class="section-different-ways-image-4 image"
        src="/assets/images/snap-puzzle.webp"
        alt=""
      />
    </div>
    <div class="section-different-ways-header">
      <h2 class="section-title">Different ways to learn</h2>
      <p class="section-text">
        Every child is different. How they learn, how they interact, and what
        makes them more motivated. STEAMius have three mediums to make it more
        fun and challenging: interactive learning videos, gamified workbooks,
        and games.
      </p>
    </div>
    <div class="section-different-ways-sub-header-1">
      <h3 class="section-sub-title">Interactive Learning Videos</h3>
      <p class="section-text">
        Our interactive videos engage your child with the tutor through
        interactive challenges.
      </p>
      <p class="section-text">
        Not only that, but we also have many videos with hands-on experiments
        and projects to develop the mind of a young explorer.
      </p>
    </div>
    <div class="section-different-ways-sub-header-2">
      <h3 class="section-sub-title">Workbook with Video Lessons</h3>
      <p class="section-text">
        Our workbook is anything but traditional. With gamification, we keep it
        fun and challenging.
      </p>
      <p class="section-text">
        For math, we believe that Singapore math provides us with the core
        methodology to teach younger kids, so we use curriculums that are
        hands-on with concrete and pictorial learning before we start
        approaching a more abstract model.
      </p>
    </div>
    <div class="section-different-ways-sub-header-3">
      <h3 class="section-sub-title">Fun games</h3>
      <p class="section-text">
        We think it's essential that every child should have the time and tools
        to develop to their fullest potential. With games, children can have fun
        while still developing logical thinking, learn how to explore virtual
        worlds to discover facts about life and objects, and much more.
      </p>
    </div>
  </section>

  <!-- Page Decor -->
  <!-- <div class="decor-earth-home">
    <EarthPlanetDecor />
  </div> -->
  <!-- <div class="decor-balloon-home">
    <BalloonDecor />
  </div>
  <div class="decor-cloud-home">
    <CloudDecor />
  </div> -->
</main>
<Footer />

<style>
  .main {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin: 0 auto;
    /* TODO: Change this once we have another image as it depends on the aspect ratio */
    /* padding-top: calc(100% * 0.578125 - 60px); */
    padding-top: 30px;
    padding-bottom: 100px;
    width: 100%;
  }

  .boxed {
    padding-left: 30px;
    padding-right: 30px;
    max-width: 480px;
  }

  .section-title {
    font-size: 27px;
    font-weight: 700;
    font-family: wonderboys;
    color: #0f4b3d;
  }

  .section-sub-title {
    font-size: 21px;
    font-weight: 700;
    font-family: wonderboys;
    color: #0f4b3d;
  }

  .section-text {
    padding-top: 15px;
    font-size: 14px;
    font-weight: 400;
    color: #164238;
    line-height: 1.4;
  }

  .image {
    width: 100%;
  }

  /****************************************************************************
  * Hero
  ****************************************************************************/
  .hero-content {
    display: grid;
    grid-template-rows: auto auto;
    position: relative;
    margin: 0 auto;
    padding: 7rem 0 0;
    max-width: 1600px;
  }

  .hero-content-left {
    display: flex;
    flex-direction: column;
    justify-content: center;
    grid-row: 2;
    /* padding: 20px 30px; */
    padding: 0 60px;
  }

  .hero-content-title {
    display: none;
    font-size: 40px;
    font-weight: 500;
    font-family: "wonderboys";
    color: #242b33;
  }

  .hero-content-text {
    display: none;
    padding: 5px 0 20px;
    font-size: 27px;
    font-weight: 500;
    color: #0b4f3f;
    line-height: 1.3;
  }

  .hero-image-container {
    padding: 0 30px;
    max-width: 480px;
    width: 100%;
    margin: 0 auto;
  }

  .hero-image-wrapper {
    position: relative;
    padding-bottom: 71.1291666667%;
    width: 100%;
    height: 0;
  }

  .hero-image {
    position: absolute;
    width: 100%;
  }

  /****************************************************************************
  * Announcements
  ****************************************************************************/
  .section-announcements {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
  }

  .slider-slide {
    display: grid;
    grid-template-columns: 54px auto 54px;
    grid-template-rows: auto auto;
    align-items: center;
    position: relative;
    padding: 20px 0 0;
    max-width: 480px;
  }

  .slider-text {
    display: flex;
    align-items: center;
    text-align: center;
    padding: 0 0 10px;
    font-size: 14px;
    color: #20364d;
    grid-column: 1 / 4;
    grid-row: 1;
  }

  .slider-button-left,
  .slider-button-right {
    cursor: pointer;
  }

  .slider-button-left {
    grid-column: 1;
    grid-row: 2;
  }

  .slider-button-right {
    grid-column: 3;
    grid-row: 2;
  }

  .slider-pagination {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .slider-pagination-circle {
    display: inline-block;
    border-radius: 10px;
    margin-top: 16px;
    width: 16px;
    height: 16px;
    background: #bdbdbd;
    transition: all 0.1s;
    margin: 0 4px;
  }

  .slider-pagination-circle--active {
    background: #4aaadd;
  }

  /****************************************************************************
  * Features
  ****************************************************************************/
  .section-features {
    display: grid;
    grid-template-rows: auto auto auto;
    padding: 60px 0 0;
  }

  .feature-icons {
    display: grid;
    grid-template-rows: repeat(6, 1fr);
    padding: 20px 0 0;
  }

  .feature-icon {
    display: grid;
    align-items: center;
    grid-template-columns: 54px 1fr;
    gap: 15px;
  }

  :global(.feature-icon svg) {
    width: 54px;
  }

  .feature-icon-text {
    font-size: 14px;
    color: #20364d;
  }

  .features-image {
    grid-row: 2;
    padding-top: 10px;
  }

  /****************************************************************************
  * Different Ways to Learn
  ****************************************************************************/
  .section-different-ways {
    display: grid;
    grid-template-rows: repeat(7, auto);
    padding: 60px 0 0;
  }

  .section-different-ways-header {
    grid-row: 1;
  }

  .section-different-ways-image-1,
  .section-different-ways-image-2,
  .section-different-ways-image-3,
  .section-different-ways-image-4 {
    padding: 40px 20px;
    border-radius: 4px;
  }

  .section-different-ways-image-1 {
    grid-row: 2;
  }

  .section-different-ways-image-2 {
    grid-row: 4;
  }

  .section-different-ways-image-3 {
    grid-row: 6;
  }

  .section-different-ways-sub-header-1 {
    grid-row: 3;
  }

  .section-different-ways-sub-header-2 {
    grid-row: 5;
  }

  .section-different-ways-sub-header-3 {
    grid-row: 7;
  }

  .section-different-ways-image-3-group {
    position: relative;
    grid-row: 6;
    margin-bottom: 115px;
  }

  .section-different-ways-image-3 {
    width: 85%;
  }

  .section-different-ways-image-4 {
    position: absolute;
    left: 45px;
    top: 125px;
    width: 85%;
  }

  /****************************************************************************
  * Decor
  ****************************************************************************/
  .decor-earth-home {
    display: none;
    position: absolute;
    top: 440px;
    left: 40px;
    width: 100px;
  }
  .decor-balloon-home {
    display: none;
    position: absolute;
    top: 1500px;
    right: 20px;
    width: 100px;
  }
  .decor-cloud-home {
    display: none;
    position: absolute;
    bottom: 25vw;
    right: 80px;
    width: 100px;
    height: 60px;
  }
  /* Safari needs size */
  :global(.decor-earth-home svg),
  :global(.decor-balloon-home svg),
  :global(.decor-cloud-home svg) {
    width: 100%;
  }

  @media all and (min-width: 400px) {
    .section-title {
      font-size: 30px;
    }

    .section-sub-title {
      font-size: 24px;
    }

    .section-text {
      font-size: 17px;
    }

    .slider-text {
      font-size: 15px;
    }

    .decor-balloon-home {
      display: flex;
    }

    .decor-cloud-home {
      display: flex;
    }

    .feature-icon {
      grid-template-columns: 70px 1fr;
    }

    :global(.feature-icon svg) {
      width: 70px;
    }

    .feature-icon-text {
      font-size: 17px;
      font-weight: 500;
    }
  }

  @media all and (min-width: 414px) {
    .slider-text {
      grid-column: 2 / 3;
      grid-row: 1;
      padding: 10px;
    }

    .slider-button-left {
      grid-column: 1;
      grid-row: 1;
    }

    .slider-button-right {
      grid-column: 3;
      grid-row: 1;
    }

    .slider-pagination {
      grid-column: 2;
      grid-row: 2;
    }
  }

  @media all and (min-width: 768px) {
    .boxed {
      max-width: 720px;
    }

    .hero-image-container {
      max-width: 540px;
    }

    .section-features {
      grid-template-columns: 1fr 1fr;
      grid-template-rows: auto auto;
    }

    .feature-icons {
      grid-template-columns: 1fr 1fr;
      grid-template-rows: 1fr 1fr 1fr;
    }

    .feature-icons {
      grid-column: 1 / 3;
      grid-row: 2;
      gap: 10px;
    }

    .features-image {
      grid-row: 1;
      grid-column: 2;
      width: 80%;
      margin: 0 auto;
      padding: 0;
    }

    .section-different-ways {
      grid-template-columns: 1fr 1fr;
      grid-template-rows: repeat(4, auto);
      gap: 40px;
    }

    .section-different-ways-header {
      grid-column: 1 / 3;
      grid-row: 1;
    }

    .section-different-ways-image-1,
    .section-different-ways-image-2,
    .section-different-ways-image-3 {
      padding: 0;
    }

    .section-different-ways-image-1 {
      grid-row: 2;
    }

    .section-different-ways-image-2 {
      grid-row: 3;
      grid-column: 2;
    }

    .section-different-ways-image-3-group {
      grid-row: 4;
    }

    .section-different-ways-sub-header-1 {
      grid-row: 2;
    }

    .section-different-ways-sub-header-2 {
      grid-row: 3;
      grid-column: 1;
    }

    .section-different-ways-sub-header-3 {
      grid-row: 4;
    }

    .decor-balloon-home {
      top: 1175px;
    }
  }

  @media all and (min-width: 992px) {
    .main {
      padding-bottom: 150px;
    }

    .boxed {
      max-width: 960px;
    }

    .section-features {
      padding: 100px 0 0;
    }

    .section-different-ways {
      padding: 100px 0 0;
    }

    .section-title {
      font-size: 40px;
    }

    .section-sub-title {
      font-size: 30px;
    }

    .section-text {
      font-size: 19px;
    }

    .feature-icon {
      grid-template-columns: 90px 1fr;
    }

    :global(.feature-icon svg) {
      width: 90px;
    }

    .feature-icon-text {
      font-size: 19px;
    }

    .hero-content {
      grid-template-columns: 1fr 1fr;
      grid-template-rows: auto;
      /* padding: 8rem 30px 60px; */
      padding: 0 30px 60px;
    }

    .hero-content-left {
      grid-column: 1;
      grid-row: 1;
      align-items: flex-start;
    }

    .hero-content-title {
      display: block;
    }

    .hero-content-text {
      display: block;
    }

    .decor-earth-home {
      display: flex;
    }
  }
</style>
