<script>
  import PageHeader from "../components/composites/PageHeader.svelte";
  import KiteDecor from "../components/assets/KiteDecor.svelte";
  import Footer from "../components/composites/Footer.svelte";

  /**
   * FIX: Breaks at > 2440px with the kite.
   */
</script>

<svelte:head>
  <title>Invest</title>
  <meta name="robots" content="noindex nofollow" />
</svelte:head>

<PageHeader title="Invest" color="indigo" />
<main class="main">
  <section>
    <p class="text">invest@steamius.com</p>
  </section>

  <!-- Page Decor -->
  <div class="decor-kite">
    <KiteDecor />
  </div>
</main>
<Footer />

<style>
  .main {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin: 0 auto;
    padding: 80px 30px;
    max-width: 1200px;
    width: 100%;
    height: calc(100vh - 200px - 25vw);
  }
  .main :global(.decor-kite) {
    width: 60px;
    position: absolute;
    bottom: 100px;
    right: 50px;
  }
  .text {
    font-size: 27px;
  }
  @media all and (min-width: 440px) {
    .main :global(.decor-kite) {
      width: 80px;
      bottom: 280px;
    }
  }
  @media all and (min-width: 540px) {
    .main {
      height: calc(100vh - 300px - 25vw);
    }
    .main :global(.decor-kite) {
      width: 80px;
    }
    .text {
      font-size: 30px;
    }
  }
  @media all and (min-width: 992px) {
    .main {
      padding: 50px 30px 150px;
      height: calc(100vh - 400px - 25vw);
    }
    .main :global(.decor-kite) {
      width: 120px;
      right: 100px;
    }
  }

  @media all and (min-width: 1200px) {
    .main {
      height: calc(100vh - 500px - 25vw);
    }
    .main :global(.decor-kite) {
      bottom: 500px;
    }
  }

  @media all and (min-width: 1400px) {
    .main {
      height: calc(100vh - 600px - 25vw);
    }
  }
</style>
