<script>
  export let label = "";
</script>

<div class="button-wrapper">
  <svg class="button" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 82"
    ><defs
      ><filter
        x="-4.8%"
        y="-16.2%"
        width="109.7%"
        height="132.4%"
        filterUnits="objectBoundingBox"
        id="button-green-a"
        ><feOffset
          dy="2"
          in="SourceAlpha"
          result="shadowOffsetOuter1"
        /><feGaussianBlur
          stdDeviation="2"
          in="shadowOffsetOuter1"
          result="shadowBlurOuter1"
        /><feColorMatrix
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.5 0"
          in="shadowBlurOuter1"
          result="shadowMatrixOuter1"
        /><feMerge
          ><feMergeNode in="shadowMatrixOuter1" /><feMergeNode
            in="SourceGraphic"
          /></feMerge
        ></filter
      ></defs
    ><g
      filter="url(#button-green-a)"
      transform="translate(4 2)"
      fill="none"
      fill-rule="evenodd"
      ><path
        d="M19.862 1.005S1.521 4.249 2.01 14.093c.489 9.845 3.56 42.147 3.825 44.3.265 2.152-.154 10.953 7.651 12.081 7.805 1.128 14.79.454 20.404 1.007 5.614.554 24.982 2.406 61.21 1.007 6.906-.267 115.95.234 121.144-1.007 5.194-1.241 30.273 4.26 30.604-15.102.33-19.361.018-28.19.018-28.19s1.862-24.623-19.146-25.17c-21.008-.548-52.446-1.398-57.384-1.007-4.938.39-64.398.839-87.989 1.006-20.845.149-59.983-2.137-62.485-2.013Z"
        fill="#C2F757"
      /><path
        d="M19.23.004a1.8 1.8 0 0 0-.225.025S9.941 1.642 4.403 5.973C1.715 8.075-.162 10.793.011 14.226c.185 3.68.734 10.502 1.363 17.71C2.425 43.987 3.7 57.119 3.866 58.464c.18 1.444.114 5.75 2.183 8.958 1.29 1.999 3.354 3.596 6.632 4.067 2.152.309 4.244.485 6.263.588 5.339.273 10.16.017 14.252.417 3.295.322 11.294 1.087 24.616 1.385 9.513.213 21.748.187 36.929-.407 4.98-.2 63.028-.106 97.039-.495 13.37-.153 23.07-.405 24.541-.758 1.326-.317 4.007-.151 7.23-.125 5.54.046 12.538-.26 17.564-3.273 3.794-2.275 6.539-6.074 6.665-12.46.146-6.966.202-12.566.216-16.88.024-7.202-.074-10.822-.085-11.23.05-.77.687-12.053-5.429-19.479-3.061-3.717-7.759-6.498-14.933-6.702-12.307-.34-28.185-.83-40.24-1.03-8.645-.143-15.341-.132-17.414.04-3.155.272-28.63.85-52.748 1.1-13.59.143-26.75.122-35.253.154-5.351.011-11.906-.141-18.737-.376C43.284 1.274 21.099-.078 19.23.004Zm.294 2.25c2.237-.051 19.74.813 37.279 1.331 9.05.267 18.108.445 25.109.36 7.767-.086 19.413-.284 31.729-.372 17.657-.125 36.682-.095 47.609-.206 4.696-.047 7.912-.106 8.915-.177 1.544-.113 5.707-.067 11.337.02 12.324.189 31.58.642 45.957.999 6.147.144 10.182 2.515 12.83 5.686 5.996 7.18 5.166 18.285 5.166 18.285a.623.623 0 0 0-.002.084s.143 3.592.18 11.218c.02 4.307.004 9.898-.09 16.854-.08 5.677-2.38 9.102-5.744 11.133-4.626 2.79-11.114 2.989-16.224 2.944-3.513-.03-6.432-.16-7.878.183-1.437.34-10.904.507-23.945.613-34.048.276-92.156-.041-97.143.142-12.021.454-22.184.552-30.601.462-16.882-.18-26.73-1.124-30.466-1.492-4.138-.408-9.015-.16-14.415-.44-1.915-.1-3.9-.268-5.94-.563-2.306-.332-3.723-1.49-4.628-2.896-1.882-2.923-1.722-6.858-1.884-8.173-.166-1.342-1.425-14.444-2.464-26.464-.621-7.183-1.163-13.98-1.344-17.647-.136-2.74 1.41-4.892 3.556-6.568 4.649-3.63 12.058-5.12 13.09-5.314l.011-.002Z"
        fill="#000"
      /><path
        d="M235 20.825c0-5.423-5.604-9.825-12.505-9.825H23.504C16.603 11 11 15.402 11 20.825v31.35C11 57.598 16.603 62 23.504 62h198.991C229.396 62 235 57.598 235 52.175v-31.35Z"
        stroke="#000"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-dasharray="1.5,4.5,0.0001,0.0001"
      /></g
    ></svg
  >
  <p class="label">{label}</p>
</div>

<style>
  .button-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all 0.2s;
  }

  .button-wrapper:hover {
    transform: scale(1.1);
  }

  /* Safari neds explicit sizing */
  .button {
    width: 180px;
  }

  .label {
    position: absolute;
    font-size: 21px;
    font-family: "wonderboys";
    color: #545517;
    cursor: pointer;
  }

  @media all and (min-width: 992px) {
    .button {
      width: 220px;
    }

    .label {
      font-size: 24px;
    }
  }
</style>
