<script>
  export let direction;
  let arrow =
    direction === "right" ? "m25 40 11-9.046L25 21" : "m36 21-11 9.046L36 40";
</script>

<!-- TODO: fix that we can set width responsively on SVG -->
<div class="button-wrapper">
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 68 68" width="54"
    ><defs
      ><linearGradient
        x1="50%"
        y1="0%"
        x2="50%"
        y2="100%"
        id="button-yellow-round-b"
        ><stop stop-color="#FFDC22" offset="0%" /><stop
          stop-color="#F0BD1C"
          offset="100%"
        /></linearGradient
      ><filter
        x="-20%"
        y="-20%"
        width="140%"
        height="140%"
        filterUnits="objectBoundingBox"
        id="button-yellow-round-a"
        ><feOffset
          dy="2"
          in="SourceAlpha"
          result="shadowOffsetOuter1"
        /><feGaussianBlur
          stdDeviation="2"
          in="shadowOffsetOuter1"
          result="shadowBlurOuter1"
        /><feColorMatrix
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.5 0"
          in="shadowBlurOuter1"
          result="shadowMatrixOuter1"
        /><feMerge
          ><feMergeNode in="shadowMatrixOuter1" /><feMergeNode
            in="SourceGraphic"
          /></feMerge
        ></filter
      ></defs
    ><g
      filter="url(#button-yellow-round-a)"
      transform="translate(4 2)"
      fill="none"
      fill-rule="evenodd"
      ><path
        d="M30 59c8.77 0 16.412-2.152 21.069-6.482C56.279 47.674 59 39.376 59 30 59 13.984 46.016 1 30 1 20.38 1 13.747 4.645 8.325 12.565 3.608 19.455 1 25.005 1 30c0 16.016 12.984 29 29 29Z"
        stroke="#000"
        stroke-width="2"
        fill="url(#button-yellow-round-b)"
      /><path
        d="M31 55c13.255 0 24-10.745 24-24S44.255 7 31 7 7 17.745 7 31s10.745 24 24 24Z"
        stroke="#AF8800"
        stroke-linecap="round"
        stroke-dasharray="3"
      /><path
        stroke="#887011"
        stroke-width="3"
        stroke-linecap="round"
        d={arrow}
      /></g
    ></svg
  >
</div>

<style>
  .button-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 54px;
    transition: all 0.2s;
  }
  .button-wrapper:hover {
    transform: scale(1.1);
  }
  @media all and (min-width: 1400px) {
    .button-wrapper {
      width: 70px;
    }
  }
</style>
