<svg viewBox="0 0 50 50" xmlns="http://www.w3.org/2000/svg"
  ><g fill="none" fill-rule="evenodd"
    ><path
      d="M29.591 5.93c-8.87-2.021-13.331-.52-16.697 2.314C9.528 11.077 6.8 17.47 8.54 28.865 10.28 40.26 17.819 45 23.705 45c5.886 0 12.009-2.816 15.35-8.516 3.343-5.7 2.929-10.585 2.929-17.813 0-7.227-3.523-10.72-12.393-12.741Z"
      fill="#E65591"
    /><circle fill="#EF629D" cx="25" cy="25" r="11" /><g
      transform="translate(17 17)"
      ><rect
        stroke="#FFF"
        fill="#C42768"
        x=".5"
        y=".5"
        width="15"
        height="12"
        rx="3"
      /><path fill="#FFF" d="m10.5 6.5-5 3v-6z" /></g
    ><path
      d="M25.016 23.169a1 1 0 0 1 1.271.62l.977 2.836a1 1 0 0 1 1.89-.65l.326.945a1 1 0 0 1 1.891-.652l.326.946a1 1 0 0 1 1.891-.651l3.256 9.455a1 1 0 0 1-.926 1.326l.667 1.936-5.204 1.792-.757-2.2c-1.126.437-1.935.118-2.429-.957a1 1 0 0 1-1.458-.533l-1.953-5.672a1 1 0 0 1 1.891-.652l-2.279-6.618a1 1 0 0 1 .62-1.271Zm1.66 7.89 1.345 3.851m-.757-8.285 1.346 3.852m.545-4.503 1.346 3.852m.87-3.558 1.017 2.908"
      stroke="#992555"
      stroke-width=".5"
      fill="#FFF"
    /><path
      fill="#8D4EFA"
      d="m6.224 36.102 2.897.777.777 2.897-2.122 2.122-2.897-.777-.777-2.897z"
    /><path
      fill="#FFA156"
      d="m44.826 18.008 1.812.845.175 1.992-1.639 1.147-1.812-.845-.175-1.992z"
    /></g
  ></svg
>
