<script>
  import PageHeader from "../components/composites/PageHeader.svelte";
  import EarthPlanetDecor from "../components/assets/EarthPlanetDecor.svelte";
  import RainbowDecor from "../components/assets/RainbowDecor.svelte";
  import Footer from "../components/composites/Footer.svelte";
</script>

<svelte:head>
  <title>Learn about our company</title>
</svelte:head>

<PageHeader title="Company" color="violet" />
<main class="main">
  <!-- <section>
    <h2 class="title">Meet the team</h2>
    <div class="team">
      <div class="team-member">
        <img
          class="team-member-image"
          src="/assets/images/company-ceo.webp"
          alt=""
        />
        <p class="team-member-name">Paulo C. Sandsten</p>
        <p class="team-member-title">CEO | Founder</p>
      </div>
      <div class="team-member">
        <img
          class="team-member-image"
          src="/assets/images/company-ux.webp"
          alt=""
        />
        <p class="team-member-name">Freyja Sandsten</p>
        <p class="team-member-title">UX Lead</p>
      </div>
    </div>
  </section> -->
  <section class="statement">
    <div class="statement-left">
      <h2 class="statement-title">Our Mission & Vision</h2>
      <p class="statement-mission">
        Our mission is to provide early education in STEAM subjects and make
        them more accessible, affordable, and applicable in the real world by
        nurturing the creative spark we see in children.
      </p>
    </div>
    <p class="statement-vision">
      We envision that we can provide parents the opportunity to make a
      life-altering choice for their children by empowering them with STEAM
      education. We see ourselves as becoming a leading part in how artificial
      intelligence, machine learning, and augmented reality can aid and be core
      teaching tools in a more digital future that adapts to our children's
      needs.
    </p>
  </section>

  <!-- Page Decor -->
  <div class="decor-earth">
    <EarthPlanetDecor />
  </div>
  <div class="decor-rainbow">
    <RainbowDecor />
  </div>
</main>
<Footer />

<style>
  .main {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin: 0 auto;
    padding: 3rem 2rem 80px;
    max-width: 1200px;
    width: 100%;
  }
  .title {
    margin: 0 0 40px;
    font-size: 36px;
    font-family: wonderboys;
    text-align: center;
    color: #565163;
  }
  .team {
    display: flex;
    flex-direction: column;
    gap: 30px;
  }
  .team-member {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .team-member-image {
    width: 60%;
    user-select: none;
  }
  .team-member-name {
    margin-top: 15px;
    font-size: 24px;
    font-family: wonderboys;
    color: #565163;
  }
  .team-member-title {
    margin: 5px 0 20px;
    font-size: 17px;
    color: #565163;
  }
  .statement {
    display: grid;
    grid-template-columns: auto auto;
    grid-template-rows: auto auto;
    /* padding-top: 80px; */
    gap: 20px;
    max-width: 800px;
  }
  .statement-left {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    grid-row: 1;
  }
  .statement-title {
    margin-bottom: 15px;
    font-size: 36px;
    font-family: wonderboys;
    color: #565163;
  }
  .statement-mission {
    font-size: 17px;
  }
  .statement-vision {
    display: flex;
    align-items: flex-end;
    font-size: 17px;
    grid-row: 2;
  }
  .main :global(.decor-rectangle svg) {
    stroke: #000;
  }
  .main :global(.decor-rainbow) {
    width: 100px;
    position: absolute;
    top: 110px;
    left: 15px;
  }
  .main :global(.decor-earth) {
    width: 60px;
    position: absolute;
    top: 180px;
    right: 50px;
  }

  @media all and (min-width: 540px) {
    .team-member-image {
      width: 80%;
    }
    .main :global(.decor-rainbow) {
      width: 150px;
      top: 170px;
      left: 15px;
    }
    .main :global(.decor-earth) {
      width: 80px;
      top: 260px;
    }
  }

  @media all and (min-width: 768px) {
    .team {
      flex-direction: row;
    }
  }

  @media all and (min-width: 992px) {
    .main {
      padding: 0 2rem 150px;
    }
    .title {
      font-size: 50px;
      margin: 0 0 4rem;
    }
    .team {
      gap: 80px;
    }
    .team-member-image {
      width: 70%;
    }
    .team-member-name {
      margin-top: 30px;
      font-size: 30px;
    }
    .statement {
      padding-top: 8rem;
    }
    .statement-left {
      /* grid-row: 1 / 3; */
    }
    .statement-title {
      margin-bottom: 10px;
      font-size: 64px;
    }
    .statement-vision {
      /* grid-row: 1/3; */
    }
    .main :global(.decor-rainbow) {
      top: 240px;
    }
    .main :global(.decor-earth) {
      width: 100px;
      top: 340px;
      right: 100px;
    }
  }

  @media all and (min-width: 1200px) {
    .main {
      padding: 6rem 2rem;
    }
    .main :global(.decor-rainbow) {
      top: 250px;
    }
    .main :global(.decor-earth) {
      top: 420px;
    }
  }

  @media all and (min-width: 1400px) {
    .main :global(.decor-earth) {
      top: 520px;
    }
  }
</style>
