<script>
  import { Link } from "svelte-navigator";
  import Logo from "../assets/Logo.svelte";

  // Controls the hamburger toggling to add/remove classes.
  let toggle = false;
  function handleToggle() {
    if (window.innerWidth < 768) {
      toggle = !toggle;
    } else {
      toggle = false;
    }
  }

  // Bind to a variable and add an event handler for scolling.
  let navigation;
  window.addEventListener("scroll", () => {
    navigation.classList.toggle("sticky", window.scrollY > 10);
  });
</script>

<nav class="navigation" bind:this={navigation}>
  <div class="logo-wrapper" class:mobile={toggle}>
    <Link class="nav-link" to="/"><Logo /></Link>
  </div>
  <ul class="list" class:mobile={toggle}>
    <li class="list-item first" on:click={handleToggle}>
      <Link class="list-nav-link" to="/">Home</Link>
    </li>
    <li class="list-item" on:click={handleToggle}>
      <Link class="list-nav-link" to="/company">Company</Link>
    </li>
    <li class="list-item" on:click={handleToggle}>
      <Link class="list-nav-link" to="/invest">Invest</Link>
    </li>
  </ul>
  <div class="hamburger" class:clicked={toggle} on:click={handleToggle}>
    <div class="bar" />
  </div>
</nav>

<style>
  .navigation {
    display: flex;
    align-items: center;
    position: fixed;
    padding: 0 20px;
    width: 100vw;
    height: 100px;
    z-index: 1;
    transition: all 0.3s;
    user-select: none;
  }
  .logo-wrapper {
    width: 150px;
    position: relative;
  }
  :global(.nav-link svg) {
    fill: #fff;
    transition: all 0.3s;
  }
  :global(.nav-link:hover svg) {
    transition: all 0.3s;
  }
  :global(.sticky) {
    top: 0;
    background: #fff;
    border-bottom: 1px solid #eee;
  }
  :global(.sticky svg) {
    fill: #195690;
  }
  :global(.sticky .list-nav-link) {
    color: #195690;
  }
  .list {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: fixed;
    margin: 0;
    padding: 8rem 3rem;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    height: 100vh;
    width: 100vw;
    list-style: none;
    font-size: 24px;
    font-family: "wonderboys";
    transition: all 0.3s;
    transform: translateY(-100vh);
    background: #fff;
    text-align: center;
  }
  .list.mobile {
    transform: translateY(0vh);
  }
  :global(.list-nav-link) {
    display: inline-block;
    color: #195690;
    text-decoration: none;
    transition: all 0.2s;
    font-size: 3rem;
  }
  .list-item {
    transform: translateY(-10vh);
    opacity: 0;
    padding: 0 20px;
  }
  .mobile .list-item {
    padding: 10px 0;
    color: #195690;
    transform: translateY(0vh);
    opacity: 1;
  }
  .hamburger {
    position: absolute;
    margin: auto;
    width: 46px;
    height: 46px;
    right: 20px;
    top: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 10;
    cursor: pointer;
  }
  .hamburger .bar {
    position: relative;
    border-radius: 2px;
    width: 40px;
    height: 3px;
    background: #fff;
  }
  .hamburger .bar:before,
  .hamburger .bar:after {
    content: "";
    position: absolute;
    border-radius: 2px;
    width: 100%;
    height: 100%;
    background: inherit;
    left: 0;
    transform-origin: center center;
    transition: all 0.1s ease, top 0.1s ease, bottom 0.1s ease;
  }
  .hamburger .bar:before {
    top: -10px;
  }
  .hamburger .bar:after {
    bottom: -10px;
  }
  :global(.sticky.navigation .hamburger .bar),
  :global(.sticky.navigation .hamburger .bar:before),
  :global(.sticky.navigation .hamburger .bar:after) {
    background: #195690;
  }
  :global(.navigation .hamburger.clicked .bar) {
    background: transparent;
  }
  :global(
      .navigation .hamburger.clicked .bar:before,
      .navigation .hamburger.clicked .bar:after
    ) {
    background: #195690;
  }
  :global(.navigation .hamburger.clicked .bar:before),
  :global(.sticky.navigation .hamburger.clicked .bar:before) {
    transform: rotate(45deg);
    top: 0;
  }
  :global(.navigation .hamburger.clicked .bar:after),
  :global(.sticky.navigation .hamburger.clicked .bar:after) {
    transform: rotate(-45deg);
    bottom: 0;
  }

  @media all and (min-width: 768px) {
    .navigation {
      padding: 0 30px;
    }
    .hamburger {
      display: none;
    }
    .list {
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      position: relative;
      padding: 0 0 0 4rem;
      height: 100%;
      width: 100%;
      transform: translateY(0);
      background: transparent;
    }
    .list-item {
      transform: translateY(0vh);
      opacity: 1;
    }
    .first {
      display: none;
    }
    :global(.list-nav-link) {
      color: #fff;
      font-size: 1.5rem;
    }
    .logo-wrapper {
      width: 180px;
    }
    :global(.list-nav-link) {
      display: inline-block;
      color: #fff;
      text-decoration: none;
      transition: all 0.2s;
    }
  }
  @media all and (min-width: 1200px) {
    :global(.nav-link:hover svg) {
      transform: scale(1.125);
    }
    :global(.list-nav-link:hover) {
      transform: scale(1.25);
    }
  }
</style>
