<svg viewBox="0 0 100 60" xmlns="http://www.w3.org/2000/svg"
  ><g fill="none" fill-rule="evenodd"
    ><path
      d="M35.807 29.523c-2.31-4.073-2.159-7.549.508-10.309 2.722-2.817 7.022-2.174 12.82 1.759-1.78-10.65 3.092-16.095 14.413-16.095 10.746 0 15.457 4.912 13.958 14.505 2.547-3.327 5.561-3.421 8.82-.176 2.594 2.583 2.634 5.323.843 7.915-.61.884-1.394 1.69-2.266 2.405-.066.054-.131.107-.196.158.312.05.642.104.986.164 1.66.287 3.321.633 4.874 1.038 4.65 1.213 7.403 2.656 7.403 4.63 0 2.936-6.878 3.95-20.973 3.502.363 3.835-.82 6.449-3.566 7.727-2.673 1.244-5.801.302-9.361-2.731-.17 7.038-6.147 10.557-17.663 10.557-11.867 0-16.341-4.39-13.014-12.934-21.195 1.14-31.66-.296-31.66-4.756 0-4.428 11.245-6.729 34.074-7.36Z"
      stroke="#1A95AD"
      fill="#56D4EF"
    /><path
      d="M63.632 5.378c11.4 0 15.684 5.569 12.855 16.707 2.68-5.34 5.852-6.18 9.515-2.524C91.497 25.047 83.446 30 83.446 30s14.024 1.816 14.024 5.518c0 .058-.004.116-.012.172-6.226.67-14.392.07-24.499-1.797 2.729 5.689 2.372 8.81-1.07 9.365-3.441.555-7.168-1.523-11.18-6.232-.638 9.161-5.68 13.742-15.125 13.742-14.167 0-7.315-12.44-7.323-12.626-16.77 1.558-28.713.843-35.827-2.147C4.059 32.54 15.524 30.54 36.826 30c-2.645-4.215-2.645-7.694 0-10.439 2.645-2.744 7.026-1.903 13.144 2.524-2.29-11.138 2.263-16.707 13.662-16.707Z"
      fill="#6CE6FF"
    /></g
  ></svg
>
