export enum HeaderColor {
  BLUE = "blue",
  INDIGO = "indigo",
  VIOLET = "violet",
  MAGENTA = "magenta",
  GRAY = "gray",
}

export enum HeaderHeight {
  DEFAULT = "default",
  LARGE = "large",
}
