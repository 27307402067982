<svg viewBox="0 0 50 50" xmlns="http://www.w3.org/2000/svg"
  ><g fill="none" fill-rule="evenodd"
    ><path
      d="M29.591 5.93c-8.87-2.021-13.331-.52-16.697 2.314C9.528 11.077 6.8 17.47 8.54 28.865 10.28 40.26 17.819 45 23.705 45c5.886 0 12.009-2.816 15.35-8.516 3.343-5.7 2.929-10.585 2.929-17.813 0-7.227-3.523-10.72-12.393-12.741Z"
      fill="#4EC0FA"
    /><circle fill="#6773F6" cx="41.5" cy="3.5" r="1.5" /><circle
      fill="#FFAF6F"
      cx="46"
      cy="12"
      r="3"
    /><circle fill="#EF629D" cx="8" cy="45" r="3" /><circle
      fill="#68CCFF"
      cx="25"
      cy="25"
      r="11"
    /><g transform="rotate(-3 302.913 -306.602)"
      ><rect
        stroke="#FFF"
        fill="#6FB0FF"
        x=".5"
        y=".5"
        width="15"
        height="20"
        rx="2"
      /><path
        fill="#FFF"
        d="m3.5 6.25-.882.464.169-.982-.714-.696.986-.143L3.5 4l.441.893.986.143-.714.696.169.982zM3.5 11.25l-.882.464.169-.982-.714-.696.986-.143L3.5 9l.441.893.986.143-.714.696.169.982zM3.5 16.25l-.882.464.169-.982-.714-.696.986-.143L3.5 14l.441.893.986.143-.714.696.169.982z"
      /><path
        stroke="#FFF"
        stroke-width=".5"
        d="M6 5h7.57M6 6h7.57M6 10h7.57M6 11h7.57M6 12h4M6 15h7.57M6 16h6"
      /></g
    ></g
  ></svg
>
