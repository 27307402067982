<script>
  export let label = "";
</script>

<div class="button-wrapper">
  <svg viewBox="0 0 257 83" xmlns="http://www.w3.org/2000/svg"
    ><defs
      ><filter
        x="-4.8%"
        y="-16%"
        width="109.6%"
        height="132%"
        filterUnits="objectBoundingBox"
        id="button-yellow-a"
        ><feOffset
          dy="2"
          in="SourceAlpha"
          result="shadowOffsetOuter1"
        /><feGaussianBlur
          stdDeviation="2"
          in="shadowOffsetOuter1"
          result="shadowBlurOuter1"
        /><feColorMatrix
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.5 0"
          in="shadowBlurOuter1"
          result="shadowMatrixOuter1"
        /><feMerge
          ><feMergeNode in="shadowMatrixOuter1" /><feMergeNode
            in="SourceGraphic"
          /></feMerge
        ></filter
      ></defs
    ><g
      filter="url(#button-yellow-a)"
      transform="translate(4 2)"
      fill="none"
      fill-rule="evenodd"
      ><path
        d="M13.303 2.45C5.082 3.742 1.578 10.677 2.057 20.46c.479 9.784-.26 36.884 0 39.023.26 2.139 3.603 10.886 11.246 12.007 7.643 1.121 14.482.45 19.98 1 5.498.55 24.464 2.392 59.94 1.001 6.763-.265 113.543.233 118.629-1 5.086-1.234 30.894 2.817 33.716-9.42 4.363-18.924 1.892-19.764.018-40.61-.761-8.471-1.924-17.466-22.495-18.01-20.573-.543-47.612-1.388-52.447-1-4.835.388-50.574-1.168-73.676-1-20.412.146-62.435-3.336-83.665 0Z"
        fill="#FBFF59"
      /><path
        d="M12.44 1.345C8.2 2.01 5.074 4.006 3.005 7.05.748 10.37-.227 14.995.044 20.483c.139 2.795.177 7.01.168 11.648C.19 43.744-.121 57.998.065 59.528c.18 1.48 1.734 5.938 5.053 9.205 1.886 1.856 4.33 3.324 7.346 3.764 2.109.307 4.157.483 6.136.586 5.23.272 9.954.017 13.963.415 3.697.368 13.444 1.314 30.15 1.478 8.293.081 18.303-.032 30.144-.502 4.88-.2 61.749-.09 95.068-.48 13.1-.154 22.604-.411 24.046-.763 3.667-.89 18.027.892 26.94-2.5 3.822-1.453 6.704-3.8 7.587-7.571 1.748-7.466 2.421-12.14 2.495-16.324.115-6.464-1.225-11.755-2.32-24.467-.446-5.184-1.14-10.527-5.853-14.225-3.372-2.646-8.824-4.496-17.853-4.757-11.952-.338-26.081-.805-36.792-1.02-7.85-.159-13.883-.177-15.941-.007-.953.081-3.531.088-7.225.063-11.834-.081-34.884-.457-53.16-.61-4.878-.04-9.415-.094-13.311-.076C82.551 1.77 58.452.057 37.798.001 28.15-.025 19.247.314 12.442 1.345Zm.543 2.207C19.633 2.476 28.353 2.068 37.806 2c20.648-.148 44.765 1.329 58.748 1.16 4.275-.038 9.325-.042 14.747.036 23.995.344 55.243 1.63 59.206 1.34 2.014-.156 7.915-.065 15.595.094 10.705.221 24.83.586 36.777.877 8.137.194 13.109 1.72 16.165 4.09 4.296 3.328 4.769 8.217 5.21 12.911 1.19 12.63 2.58 17.882 2.51 24.308-.046 4.108-.695 8.698-2.37 16.033-.972 4.245-5.004 6.332-9.848 7.388-8.721 1.9-20.008.594-23.193 1.36-1.41.339-10.681.499-23.456.603-33.356.272-90.284-.028-95.17.154-14.787.57-26.704.579-35.97.359-12.91-.307-20.663-1.062-23.856-1.382-4.054-.406-8.832-.16-14.121-.438a62.749 62.749 0 0 1-5.82-.56c-2.356-.345-4.226-1.538-5.697-2.989-2.897-2.855-4.286-6.737-4.443-8.03-.184-1.517.15-15.658.182-27.178.012-4.676-.024-8.924-.16-11.74-.245-5.011.568-9.245 2.63-12.275 1.653-2.427 4.127-4.04 7.51-4.569Z"
        fill="#000"
      /><path
        d="M230 21.825c0-5.423-5.502-9.825-12.281-9.825H22.28C15.503 12 10 16.402 10 21.825v31.35C10 58.598 15.503 63 22.28 63H217.72C224.498 63 230 58.598 230 53.175v-31.35Z"
        stroke="#000"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-dasharray="1.5,4.5,0.0001,0.0001"
      /></g
    ></svg
  >
  <p class="label">{label}</p>
</div>

<style>
  .button-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 250px;
    transition: all 0.2s;
  }
  .button-wrapper:hover {
    transform: scale(1.1);
  }
  .label {
    position: absolute;
    font-size: 27px;
    font-family: "wonderboys";
    color: #545517;
    cursor: pointer;
  }
</style>
