<svg viewBox="0 0 50 50" xmlns="http://www.w3.org/2000/svg"
  ><g fill="none" fill-rule="evenodd"
    ><path
      d="M29.591 5.93c-8.87-2.021-13.331-.52-16.697 2.314C9.528 11.077 6.8 17.47 8.54 28.865 10.28 40.26 17.819 45 23.705 45c5.886 0 12.009-2.816 15.35-8.516 3.343-5.7 2.929-10.585 2.929-17.813 0-7.227-3.523-10.72-12.393-12.741Z"
      fill="#FFDA4C"
    /><circle fill="#FFE378" cx="25" cy="25" r="11" /><g stroke="#FFF"
      ><path
        d="m27.33 21.683 4.206-3.944M21.755 21.22 16.857 16M21.5 26.5 16 29.913M24.571 28.174 23.714 36M28.731 26.757l4.714 2.703"
      /><g fill="#FFC94F" transform="translate(12 13)"
        ><ellipse cx="13.433" cy="10.758" rx="5.133" ry="5.161" /><ellipse
          cx="4.6"
          cy="2.613"
          rx="2.1"
          ry="2.113"
        /><ellipse cx="20.6" cy="3.613" rx="2.1" ry="2.113" /><ellipse
          cx="22.6"
          cy="16.613"
          rx="2.1"
          ry="2.113"
        /><ellipse cx="11.6" cy="23.613" rx="2.1" ry="2.113" /><ellipse
          cx="2.6"
          cy="17.613"
          rx="2.1"
          ry="2.113"
        /></g
      ></g
    ><path
      fill="#5561E6"
      d="m8.845 4.187 1.14 2.057-1.603 1.72-2.132-.995.287-2.333z"
    /><path
      fill="#55C8E6"
      d="m44.025 33.959 2.618 1.334-.46 2.902-2.903.46-1.334-2.619z"
    /><path
      fill="#8D4EFA"
      d="m39.744 40.22 1.11 1.37-.96 1.479-1.704-.457-.092-1.76z"
    /></g
  ></svg
>
