<script>
  import { Link } from "svelte-navigator";
  import PageHeader from "../components/composites/PageHeader.svelte";
  import ButtonYellow from "../components/assets/ButtonYellow.svelte";
</script>

<svelte:head>
  <title>404 | Not Found</title>
  <meta name="robots" content="noindex nofollow" />
</svelte:head>

<PageHeader title="404" color="gray" />
<main class="main">
  <h1 class="title">OOOPS!</h1>
  <p class="info">Seems like we can't find the page you are looking for.</p>
  <Link to="/"><ButtonYellow label="Let's go home." /></Link>
</main>

<!-- TODO: Add footer -->

<!-- TODO: illustrator should add a sad face character here or a "I don't know" action -->
<style>
  .main {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
  .title {
    margin: 0;
    font-size: 150px;
    font-family: wonderboys;
    color: #444;
    text-shadow: -5px 5px #ffc309;
  }
  .info {
    margin: 0 0 2rem;
    font-size: 27px;
    font-family: wonderboys;
    color: #444;
  }
</style>
