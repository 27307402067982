<svg
  viewBox="0 0 135 135"
  xmlns="http://www.w3.org/2000/svg"
  xml:space="preserve"
  style="fill-rule:evenodd;clip-rule:evenodd;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:1.5"
  ><g transform="translate(506.503 -4727.497)"
    ><circle cx="-439" cy="4795" r="66" style="fill:#66b7da" /><clipPath id="a"
      ><circle cx="-439" cy="4795" r="66" /></clipPath
    ><g clip-path="url(#a)"
      ><path
        d="M-358 4722s14.73-8.57 20-1 1.456 15.86-3 19c-4.456 3.14-12.008 13.75-3 14s2.275 22.55-6 14-21.333-15.63-21-5c.333 10.63 6.655 18.7 16 18.85 9.345.15 29.795-5.28 30 13.15.205 18.43-4.898 21.13-5 29-.102 7.87 5.062 12.72-9 10-14.062-2.72-42.215-37.57-42-59 .215-21.43 11.368-50.64 23-53ZM-313 4713c-3.402 1.62-12.85 5.27-11.994 11 .856 5.73 12.775 32.59 27.994 28 9.867-2.98 10.355-15.99 16-19 17.779-9.47-6.693-32.09-32-20ZM-259.708 4726.48s-12.084 13.96-9.292 19.52c2.792 5.56 4.987 4.46 8 3s3.091 4.74 2 9c-1.091 4.26-4.134 8.82 4 6 8.134-2.82 16.102-7.17 16-11.47-.102-4.31-3 23.79-3 23.79s-8.014 9.22-17 2.68c-8.986-6.54 1.723-10.13-7-11-8.723-.87-8.407 6.32-8.927 13.85-.52 7.54-4.754 12.04-10.073 15.15-5.319 3.11-9.444 9.56 2 10 11.444.44 13.919-1.24 17 3 3.081 4.24-4 19-4 19l39-66-4-17-24.708-19.52Z"
        style="fill:#44ba4b;stroke:#27732b;stroke-width:1px"
        transform="translate(-128 27)"
      /><path
        d="M-257.254 4729.7A65.714 65.714 0 0 1-245 4768c0 36.43-29.574 66-66 66s-66-29.57-66-66c0-14.27 4.54-27.49 12.254-38.3a58.793 58.793 0 0 0-5.254 24.36c0 32.56 26.437 59 59 59s59-26.44 59-59c0-8.68-1.879-16.92-5.254-24.36Z"
        style="fill:#4497ba;fill-opacity:.49"
        transform="translate(-128 27)"
      /></g
    ><path
      d="M-439 4728.47c-18.399-.1-35.109 7.28-47.237 19.29-12.174 12.06-19.722 28.77-19.801 47.24.003 7.23 1.135 14.2 3.275 20.72 3.333 10.15 9.07 19.21 16.493 26.55a66.886 66.886 0 0 0 31.707 17.56c3.521.83 7.149 1.37 10.857 1.64 1.555.12 3.123.2 4.706.22 12.826.21 24.884-3.19 35.158-9.31 4.561-2.73 8.779-5.97 12.525-9.7 12.225-12.15 19.625-29.1 19.548-47.68-.117-18.51-7.711-35.24-19.933-47.3-12.158-11.99-28.887-19.36-47.298-19.23Zm0 1.06c18.016.13 34.284 7.55 46.022 19.45 11.674 11.83 18.861 28.1 18.747 46.02-.074 17.84-7.462 33.9-19.132 45.64-3.574 3.59-7.526 6.81-11.831 9.53-9.807 6.21-21.36 9.93-33.806 10.14a58.97 58.97 0 0 1-4.617-.11 62.213 62.213 0 0 1-10.639-1.65c-11.88-2.9-22.47-9.08-30.794-17.5a65.435 65.435 0 0 1-15.752-25.97 65.326 65.326 0 0 1-3.16-20.08c-.077-17.96 7.158-34.25 18.879-46.08 11.767-11.89 28.055-19.29 46.083-19.39Z"
      style="fill:#2f7c3f"
    /></g
  >
</svg>
