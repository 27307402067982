<svg
  viewBox="0 0 127 91"
  xmlns="http://www.w3.org/2000/svg"
  xml:space="preserve"
  style="fill-rule:evenodd;clip-rule:evenodd;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:1.5"
  ><path
    d="M4279 6269s-4.01-37.65 15-60c21.07-24.78 64.88-7.43 82 21 2.04 1.14-7 5-7 5s-33.35-39.46-62-22-18 60-18 60l-10-4Z"
    style="fill:#f44759;stroke:#512cb0;stroke-width:1px"
    transform="translate(-4266.592 -6196.626)"
  /><path
    d="M4263 6219.03h6s-3.98-44.67 12-52.03c15.98-7.36 33.22-6.39 56 20 1.94 2.25 6-4.14 6-4.14s-21.78-35.42-55-27.86c-33.22 7.56-26.11 52.28-25 64.03Z"
    style="fill:#fff262;stroke:#512cb0;stroke-width:1px"
    transform="translate(-4242.084 -6146.277)"
  /><path
    d="m4269 6209.69 5 3.62c.41.06-9.43-30.14 8-47.31 10.62-10.46 32.22-5.39 55 21 1.94 2.25 6-4.14 6-4.14s-22.73-40.79-55-29.86c-28.22 9.56-20.11 44.94-19 56.69Z"
    style="fill:#62ff86;stroke:#512cb0;stroke-width:1px"
    transform="translate(-4242.084 -6136.94)"
  /><path
    d="m4269 6209.69 5 3.62c.41.06-7.43-28.14 10-45.31 11.13-10.97 26.22-5.39 43 17 1.78 2.38 7-.14 7-.14s-23.7-37.74-47-29.86c-24.43 10.09-19.11 42.94-18 54.69Z"
    style="fill:#62beff;stroke:#512cb0;stroke-width:1px"
    transform="translate(-4238.084 -6134.578)"
  /><path
    d="M4216 6202.03s-1.02-15.98 10-16.03c11.02-.05 10.08 13 10.08 13s2.2-14.28 12.92-14c10.72.28 8.82 11.72 4.86 15.58 4.78-6.75 14.58-8.23 15.18 5.07.68 15.06-8.51 13.86-16.59 6.21 6.03 9.42-.22 17.72-7.45 16.14-3.3.36-7.29-2.83-8.92-10s1.41 10.65-12.94 11c-8.96.22-14-9.97-7.14-17-7.51 6.03-12.59-1.79-11-6.35 1.81-5.19 5.61-7.12 11-3.62Z"
    style="fill:#fff;stroke:#7456c0;stroke-width:1.25px"
    transform="matrix(.744 -.29018 .29018 .744 -4928.769 -3319.618)"
  /><path
    d="M4216 6202.03s-1.02-15.98 10-16.03c11.02-.05 10.08 13 10.08 13s2.2-14.28 12.92-14c10.72.28 8.82 11.72 4.86 15.58 4.78-6.75 14.58-8.23 15.18 5.07.68 15.06-8.51 13.86-16.59 6.21 6.03 9.42-.22 17.72-7.45 16.14-3.3.36-7.29-2.83-8.92-10s1.41 10.65-12.94 11c-8.96.22-14-9.97-7.14-17-7.51 6.03-12.59-1.79-11-6.35 1.81-5.19 5.61-7.12 11-3.62Z"
    style="fill:#fff;stroke:#7456c0;stroke-width:1.25px"
    transform="matrix(.76993 -.21203 .21204 .76993 -4477.606 -3836.598)"
  />
</svg>
