<svg
  viewBox="0 0 97 146"
  xmlns="http://www.w3.org/2000/svg"
  xmlns:xlink="http://www.w3.org/1999/xlink"
  ><defs
    ><ellipse id="balloon-a" cx="48.5" cy="36.5" rx="21.5" ry="26.5" /><filter
      x="-7%"
      y="-5.7%"
      width="114%"
      height="111.3%"
      filterUnits="objectBoundingBox"
      id="balloon-b"
      ><feOffset
        dx="2"
        dy="-6"
        in="SourceAlpha"
        result="shadowOffsetInner1"
      /><feComposite
        in="shadowOffsetInner1"
        in2="SourceAlpha"
        operator="arithmetic"
        k2="-1"
        k3="1"
        result="shadowInnerInner1"
      /><feColorMatrix
        values="0 0 0 0 0.905882353 0 0 0 0 0.298039216 0 0 0 0 0.619607843 0 0 0 1 0"
        in="shadowInnerInner1"
      /></filter
    ></defs
  ><g fill="none" fill-rule="evenodd"
    ><path
      d="M35.693 63.125c-9.713 9.442-11.756 18.38-6.13 26.81 8.437 12.647 17.22 24.562 14.415 34.015-1.87 6.302-6.676 10.02-14.416 11.153"
      stroke="#B44480"
    /><g transform="rotate(26 48.5 36.5)"
      ><use fill="#FF6DBB" xlink:href="#balloon-a" /><use
        fill="#000"
        filter="url(#balloon-b)"
        xlink:href="#balloon-a"
      /><ellipse
        stroke="#B44480"
        stroke-linejoin="square"
        cx="48.5"
        cy="36.5"
        rx="21"
        ry="26"
      /></g
    ><path
      d="M39.687 60.19a2.295 2.295 0 0 0-3.59-1.99l-.608.417a2.5 2.5 0 0 0 .202 4.248l.255.142a2.5 2.5 0 0 0 3.71-2.08l.031-.736Z"
      stroke="#B44480"
      fill="#E74C9E"
    /></g
  ></svg
>
