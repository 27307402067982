<script>
  import Clouds from "../assets/Clouds.svelte";
  import { HeaderColor, HeaderHeight } from "../../types/enums";

  export let title = "";
  export let color = HeaderColor.BLUE;
  export let height = HeaderHeight.DEFAULT;

  /**
   * FIX: Breaks at > 2440px with the clouds.
   */
</script>

<header class="header {color} {height}">
  <p class="title">{title}</p>
  <!-- <img
    class="header-image image"
    src="/assets/images/background-tropical-rainforest@2560.webp"
    alt=""
  /> -->
  <Clouds />
  <slot />
</header>

<!-- <img
  class="header-image-foreground image"
  src="/assets/images/toucan-bird.webp"
  alt=""
/> -->

<style>
  .header {
    display: flex;
    position: relative;
    width: 100%;
    user-select: none;
    overflow: hidden;
  }

  .header-image {
    position: absolute;
    width: 100%;
    height: auto;
  }

  .header-image-foreground {
    position: absolute;
    width: 60%;
    right: -70px;
    top: 100px;
  }

  /****************************************************************************
  * Size
  ****************************************************************************/
  .default {
    height: 200px;
  }

  /* .large {
    height: 800px;
  } */

  /****************************************************************************
  * Colors
  ****************************************************************************/
  .blue {
    background: radial-gradient(#79b6fd, #004aa0);
    color: #164880;
  }

  .indigo {
    background: radial-gradient(#6a3ff6, #2e1091);
    color: #401faf;
  }

  .violet {
    background: radial-gradient(#9f3ff6, #480281);
    color: #590e98;
  }

  .magenta {
    background: radial-gradient(#e53ff6, #860971);
    color: #9f1a94;
  }

  .gray {
    background: radial-gradient(#989898, #3c3c3c);
    color: #595959;
  }

  .title {
    position: absolute;
    bottom: 12%;
    right: 0;
    font-size: 22vw;
    font-family: "wonderboys";
    margin: 0;
  }

  @media all and (min-width: 540px) {
    .default {
      height: 300px;
    }
  }

  @media all and (min-width: 768px) {
  }

  @media all and (min-width: 992px) {
    .default {
      height: 400px;
    }

    .large {
      height: 800px;
    }
  }

  @media all and (min-width: 1200px) {
    .title {
      bottom: 2%;
    }
  }

  @media all and (min-width: 1400px) {
  }
</style>
