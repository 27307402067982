<div class="clouds-wrapper">
  <svg
    class="small"
    viewBox="0 0 1440 235"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    xml:space="preserve"
    xmlns:serif="http://www.serif.com/"
    style="fill-rule:evenodd;clip-rule:evenodd;stroke-linejoin:round;stroke-miterlimit:2;"
  >
    <g transform="matrix(1,0,0,1,-7700,-181.797)">
      <g transform="matrix(1.5222,0,0,3.50663,8392.6,322.61)">
        <g
          id="clouds"
          transform="matrix(0.972305,0,0,0.554946,-13.6959,-167.562)"
        >
          <g transform="matrix(0.651201,0,0,0.43339,-453.874,-883.617)">
            <clipPath id="_clip1">
              <path
                d="M98.067,2833C152.154,2833 196,2795.84 196,2750C196,2704.16 195.137,2668.22 98.067,2667C55.947,2667 7.848,2698.37 0.135,2723C-2.842,2776.91 43.981,2833 98.067,2833Z"
              />
            </clipPath>
            <g clip-path="url(#_clip1)">
              <rect
                x="-5"
                y="2662"
                width="206"
                height="176"
                style="fill:white;"
              />
            </g>
          </g>
          <g transform="matrix(0.656944,0,0,0.513877,-455,-1154.8)">
            <clipPath id="_clip2">
              <path
                d="M181,2833C199.225,2833 214,2820.46 214,2805C214,2789.54 199.225,2777 181,2777C162.775,2777 148,2789.54 148,2805C148,2820.46 162.775,2833 181,2833Z"
              />
            </clipPath>
            <g clip-path="url(#_clip2)">
              <rect
                x="143"
                y="2772"
                width="76"
                height="66"
                style="fill:white;"
              />
            </g>
          </g>
          <g
            transform="matrix(0.456351,-0.369653,-0.472567,-0.356968,953.242,1399.19)"
          >
            <clipPath id="_clip3">
              <path
                d="M318,2817C336.225,2817 351,2829.54 351,2845C351,2860.46 336.226,2873 318,2873C299.775,2873 285,2860.46 285,2845C285,2829.54 299.774,2817 318,2817Z"
              />
            </clipPath>
            <g clip-path="url(#_clip3)">
              <rect
                x="280"
                y="2812"
                width="76"
                height="66"
                style="fill:white;"
              />
            </g>
          </g>
          <g transform="matrix(0.656944,0,0,0.513877,-455,-1169.19)">
            <clipPath id="_clip4">
              <path
                d="M532,2847C550.225,2847 565,2834.46 565,2819C565,2803.54 550.225,2791 532,2791C513.775,2791 499,2803.54 499,2819C499,2834.46 513.775,2847 532,2847Z"
              />
            </clipPath>
            <g clip-path="url(#_clip4)">
              <rect
                x="494"
                y="2786"
                width="76"
                height="66"
                style="fill:white;"
              />
            </g>
          </g>
          <g transform="matrix(0.656944,0,0,0.513877,-455,-1169.19)">
            <clipPath id="_clip5">
              <path
                d="M531.857,2847C550.003,2847 573,2839.13 573,2823.67C573,2808.2 567.041,2791 539.822,2791C512.603,2791 499,2803.54 499,2819C499,2834.46 513.711,2847 531.857,2847Z"
              />
            </clipPath>
            <g clip-path="url(#_clip5)">
              <rect
                x="494"
                y="2786"
                width="84"
                height="66"
                style="fill:white;"
              />
            </g>
          </g>
          <g transform="matrix(0.656944,0,0,0.513877,-455,-1141.44)">
            <clipPath id="_clip6">
              <path
                d="M626.76,2820C645.118,2820 660,2807.46 660,2792C660,2776.54 645.118,2764 626.76,2764C608.402,2764 597,2785.87 597,2801.34C597,2816.8 608.402,2820 626.76,2820Z"
              />
            </clipPath>
            <g clip-path="url(#_clip6)">
              <rect
                x="592"
                y="2759"
                width="73"
                height="66"
                style="fill:white;"
              />
            </g>
          </g>
          <g transform="matrix(0.656944,0,0,0.513877,-455,-1144.52)">
            <clipPath id="_clip7">
              <path
                d="M812.097,2823C830.375,2823 853,2810.46 853,2795C853,2779.54 830.375,2767 812.097,2767C793.818,2767 779,2779.54 779,2795C779,2810.46 793.818,2823 812.097,2823Z"
              />
            </clipPath>
            <g clip-path="url(#_clip7)">
              <rect
                x="774"
                y="2762"
                width="84"
                height="66"
                style="fill:white;"
              />
            </g>
          </g>
          <g transform="matrix(0.656944,0,0,0.513877,-455,-1141.44)">
            <clipPath id="_clip8">
              <path
                d="M921,2820C939.225,2820 954,2807.46 954,2792C954,2776.54 939.225,2764 921,2764C902.775,2764 888,2776.54 888,2792C888,2807.46 902.775,2820 921,2820Z"
              />
            </clipPath>
            <g clip-path="url(#_clip8)">
              <rect
                x="883"
                y="2759"
                width="76"
                height="66"
                style="fill:white;"
              />
            </g>
          </g>
          <g transform="matrix(0.656944,0,0,0.513877,-455,-1195.91)">
            <clipPath id="_clip9">
              <path
                d="M1092.22,2873C1110.3,2873 1112,2860.46 1112,2845C1112,2829.54 1099.38,2817 1082.3,2817C1065.21,2817 1060,2829.54 1060,2845C1060,2860.46 1074.14,2873 1092.22,2873Z"
              />
            </clipPath>
            <g clip-path="url(#_clip9)">
              <rect
                x="1055"
                y="2812"
                width="62"
                height="66"
                style="fill:white;"
              />
            </g>
          </g>
          <g transform="matrix(0.656944,0,0,0.513877,-455,-1182.55)">
            <clipPath id="_clip10">
              <path
                d="M1287,2860C1305.23,2860 1320,2847.46 1320,2832C1320,2816.54 1305.23,2804 1287,2804C1268.78,2804 1254,2816.54 1254,2832C1254,2847.46 1268.78,2860 1287,2860Z"
              />
            </clipPath>
            <g clip-path="url(#_clip10)">
              <rect
                x="1249"
                y="2799"
                width="76"
                height="66"
                style="fill:white;"
              />
            </g>
          </g>
          <g transform="matrix(0.656944,0,0,0.393147,-455,-788.986)">
            <clipPath id="_clip11">
              <path
                d="M279,2847C333.124,2847 377,2809.84 377,2764C377,2718.16 333.124,2681 279,2681C224.876,2681 181,2718.16 181,2764C181,2809.84 224.876,2847 279,2847Z"
              />
            </clipPath>
            <g clip-path="url(#_clip11)">
              <rect
                x="176"
                y="2676"
                width="206"
                height="176"
                style="fill:white;"
              />
            </g>
          </g>
          <g transform="matrix(0.656944,0,0,0.513877,-455,-1183.58)">
            <clipPath id="_clip12">
              <path
                d="M419,2916C473.124,2916 540,2877.84 540,2832C540,2786.16 473.124,2750 419,2750C364.876,2750 321,2787.16 321,2833C321,2878.84 364.876,2916 419,2916Z"
              />
            </clipPath>
            <g clip-path="url(#_clip12)">
              <rect
                x="316"
                y="2745"
                width="229"
                height="176"
                style="fill:white;"
              />
            </g>
          </g>
          <g transform="matrix(0.656944,0,0,0.393147,-455,-764.497)">
            <clipPath id="_clip13">
              <path
                d="M549.169,2820C603.185,2820 622.905,2790.64 622.905,2744.8C622.905,2698.96 627.46,2654 573.443,2654C519.427,2654 460,2669.22 460,2715.05C460,2760.89 495.153,2820 549.169,2820Z"
              />
            </clipPath>
            <g clip-path="url(#_clip13)">
              <rect
                x="455"
                y="2649"
                width="173"
                height="176"
                style="fill:white;"
              />
            </g>
          </g>
          <g transform="matrix(0.656944,0,0,0.513877,-455,-1112.66)">
            <clipPath id="_clip14">
              <path
                d="M714,2847C768.124,2847 812,2809.84 812,2764C812,2718.16 768.124,2681 714,2681C659.876,2681 616,2718.16 616,2764C616,2809.84 659.876,2847 714,2847Z"
              />
            </clipPath>
            <g clip-path="url(#_clip14)">
              <rect
                x="611"
                y="2676"
                width="206"
                height="176"
                style="fill:white;"
              />
            </g>
          </g>
          <g transform="matrix(0.656944,0,0,0.355999,-455,-655.468)">
            <clipPath id="_clip15">
              <path
                d="M850,2808C904.124,2808 948,2770.84 948,2725C948,2679.16 904.124,2642 850,2642C795.876,2642 752,2679.16 752,2725C752,2770.84 795.876,2808 850,2808Z"
              />
            </clipPath>
            <g clip-path="url(#_clip15)">
              <rect
                x="747"
                y="2637"
                width="206"
                height="176"
                style="fill:white;"
              />
            </g>
          </g>
          <g transform="matrix(0.656944,0,0,0.513877,-455,-1126.02)">
            <clipPath id="_clip16">
              <path
                d="M1019,2860C1073.12,2860 1117,2822.84 1117,2777C1117,2731.16 1073.12,2694 1019,2694C964.876,2694 921,2731.16 921,2777C921,2822.84 964.876,2860 1019,2860Z"
              />
            </clipPath>
            <g clip-path="url(#_clip16)">
              <rect
                x="916"
                y="2689"
                width="206"
                height="176"
                style="fill:white;"
              />
            </g>
          </g>
          <g transform="matrix(0.656944,0,0,0.513877,-455,-1170.22)">
            <clipPath id="_clip17">
              <path
                d="M1179.95,2903C1234.05,2903 1298.89,2853.93 1295.9,2810.46C1292.91,2767 1234.05,2737 1179.95,2737C1125.86,2737 1082,2774.16 1082,2820C1082,2865.84 1125.86,2903 1179.95,2903Z"
              />
            </clipPath>
            <g clip-path="url(#_clip17)">
              <rect
                x="1077"
                y="2732"
                width="224"
                height="176"
                style="fill:white;"
              />
            </g>
          </g>
          <g transform="matrix(0.678939,0,0,0.513877,-360.833,-1143.5)">
            <clipPath id="_clip18">
              <path
                d="M1179.95,2903C1234.05,2903 1298.89,2853.93 1295.9,2810.46C1292.91,2767 1234.05,2737 1179.95,2737C1125.86,2737 1082,2774.16 1082,2820C1082,2865.84 1125.86,2903 1179.95,2903Z"
              />
            </clipPath>
            <g clip-path="url(#_clip18)">
              <rect
                x="1077"
                y="2732"
                width="224"
                height="176"
                style="fill:white;"
              />
            </g>
          </g>
          <g
            id="_-Rectangle-"
            serif:id="(Rectangle)"
            transform="matrix(0.692488,0,0,0.367591,-5810.27,204.485)"
          >
            <rect
              x="7735"
              y="259.797"
              width="1405"
              height="137"
              style="fill:white;"
            />
          </g>
        </g>
      </g>
    </g>
  </svg>
  <!-- Larger cloud -->
  <svg class="large" viewBox="0 0 2880 262" xmlns="http://www.w3.org/2000/svg"
    ><g fill="#FFF" fill-rule="evenodd"
      ><path
        d="M98.067 249C152.154 249 196 211.84 196 166s-.863-81.777-97.933-83c-42.12 0-90.22 31.37-97.932 56-2.977 53.91 43.846 110 97.932 110Z"
      /><ellipse cx="181" cy="111" rx="33" ry="28" /><ellipse
        transform="rotate(-46 318 71)"
        cx="318"
        cy="71"
        rx="33"
        ry="28"
      /><ellipse cx="532" cy="97" rx="33" ry="28" /><path
        d="M531.857 125c18.146 0 41.143-7.868 41.143-23.332S567.041 69 539.822 69C512.602 69 499 81.536 499 97s14.71 28 32.857 28ZM626.76 152c18.358 0 33.24-12.536 33.24-28s-14.882-28-33.24-28C608.402 96 597 117.872 597 133.336 597 148.8 608.402 152 626.76 152ZM812.097 149C830.375 149 853 136.464 853 121s-22.625-28-40.903-28C793.817 93 779 105.536 779 121s14.818 28 33.097 28Z"
      /><ellipse cx="921" cy="124" rx="33" ry="28" /><path
        d="M1092.22 99c18.082 0 19.78-12.536 19.78-28s-12.617-28-29.702-28S1060 55.536 1060 71s14.137 28 32.22 28Z"
      /><ellipse cx="1287" cy="84" rx="33" ry="28" /><ellipse
        cx="279"
        cy="152"
        rx="98"
        ry="83"
      /><path
        d="M419 166c54.124 0 121-38.16 121-84S473.124 0 419 0c-54.124 0-98 37.16-98 83s43.876 83 98 83Z"
      /><path
        d="M549.169 262c54.016 0 73.736-29.364 73.736-75.203 0-45.84 4.555-90.797-49.462-90.797C519.427 96 460 111.216 460 157.055 460 202.895 495.153 262 549.169 262Z"
      /><ellipse cx="714" cy="152" rx="98" ry="83" /><ellipse
        cx="850"
        cy="191"
        rx="98"
        ry="83"
      /><ellipse cx="1019" cy="139" rx="98" ry="83" /><path
        d="M1179.954 179c54.099 0 118.94-49.073 115.946-92.537C1292.907 43 1234.053 13 1179.954 13 1125.856 13 1082 50.16 1082 96s43.856 83 97.954 83Z"
      /><path
        d="M1342.061 237C1396.22 237 1463 184.84 1463 139s-66.78-68-120.939-68c-54.157 0-98.061 37.16-98.061 83s43.904 83 98.061 83Z"
      /><g transform="translate(1438 16)"
        ><ellipse cx="33" cy="111" rx="33" ry="28" /><ellipse
          transform="rotate(-46 170 71)"
          cx="170"
          cy="71"
          rx="33"
          ry="28"
        /><ellipse cx="384" cy="97" rx="33" ry="28" /><path
          d="M383.857 125c18.146 0 41.143-7.868 41.143-23.332S419.041 69 391.822 69C364.602 69 351 81.536 351 97s14.71 28 32.857 28ZM478.76 152c18.358 0 33.24-12.536 33.24-28s-14.882-28-33.24-28C460.402 96 449 117.872 449 133.336 449 148.8 460.402 152 478.76 152ZM664.097 149C682.375 149 705 136.464 705 121s-22.625-28-40.903-28C645.817 93 631 105.536 631 121s14.818 28 33.097 28Z"
        /><ellipse cx="773" cy="124" rx="33" ry="28" /><path
          d="M944.22 99C962.302 99 964 86.464 964 71s-12.617-28-29.702-28S912 55.536 912 71s14.137 28 32.22 28Z"
        /><ellipse cx="1139" cy="84" rx="33" ry="28" /><ellipse
          cx="131"
          cy="152"
          rx="98"
          ry="83"
        /><path
          d="M271 166c54.124 0 121-38.16 121-84S325.124 0 271 0c-54.124 0-98 37.16-98 83s43.876 83 98 83Z"
        /><path
          d="M401.169 262c54.016 0 73.736-29.364 73.736-75.203 0-45.84 4.555-90.797-49.462-90.797C371.427 96 312 111.216 312 157.055 312 202.895 347.153 262 401.169 262Z"
        /><ellipse cx="566" cy="152" rx="98" ry="83" /><ellipse
          cx="702"
          cy="191"
          rx="98"
          ry="83"
        /><ellipse cx="871" cy="139" rx="98" ry="83" /><path
          d="M1031.954 179c54.099 0 118.94-49.073 115.946-92.537C1144.907 43 1086.053 13 1031.954 13 977.856 13 934 50.16 934 96s43.856 83 97.954 83Z"
        /><path
          d="M1194.061 237C1248.22 237 1315 184.84 1315 139s-66.78-68-120.939-68c-54.157 0-98.061 37.16-98.061 83s43.904 83 98.061 83Z"
        /><path
          d="M1368.067 232c54.087 0 97.933-37.16 97.933-83s-.863-81.777-97.933-83c-42.12 0-90.22 31.37-97.932 56-2.977 53.91 43.846 110 97.932 110Z"
        /></g
      ><path d="M0 144h2880v118H0z" /></g
    ></svg
  >
</div>

<style>
  .clouds-wrapper {
    display: flex;
    position: absolute;
    /* Safari iOS needs the extra pixel. Might be more devices and cases. */
    bottom: -1px;
    width: 100%;
  }

  .small {
    width: 100%;
    height: 100%;
  }

  .large {
    display: none;
    width: 100%;
    height: 100%;
  }

  @media only screen and (min-width: 1200px) {
    .small {
      display: none;
    }
    .large {
      display: block;
    }
  }
</style>
