<svg viewBox="0 0 50 50" xmlns="http://www.w3.org/2000/svg"
  ><g fill="none" fill-rule="evenodd"
    ><path
      d="M29.591 5.93c-8.87-2.021-13.331-.52-16.697 2.314C9.528 11.077 6.8 17.47 8.54 28.865 10.28 40.26 17.819 45 23.705 45c5.886 0 12.009-2.816 15.35-8.516 3.343-5.7 2.929-10.585 2.929-17.813 0-7.227-3.523-10.72-12.393-12.741Z"
      fill="#8D4EFA"
    /><circle fill="#9F68FF" cx="25" cy="25" r="11" /><path
      fill="#FFB93E"
      d="m11 11-2.351 1.236.449-2.618-1.902-1.854 2.628-.382L11 5l1.176 2.382 2.628.382-1.902 1.854.449 2.618z"
    /><path
      fill="#4EF374"
      d="m42.092 24.782-2.656.034 1.589-2.129-.853-2.515 2.515.852 2.129-1.588-.034 2.656 2.169 1.534-2.537.788-.788 2.537z"
    /><path
      d="M18.084 21.087c.266-.013.536.011.803.073 1.571.363 2.556 1.921 2.195 3.483-.36 1.562-1.928 2.531-3.5 2.168a2.948 2.948 0 0 1-.754-.286l-.517 2.246a1.5 1.5 0 0 0 1.124 1.8l10.718 2.474a1.5 1.5 0 0 0 1.8-1.124l2.274-9.855a1.5 1.5 0 0 0-1.124-1.8l-2.845-.655.367-.594c.152-.245.263-.516.33-.803.335-1.452-.583-2.906-2.054-3.246-1.47-.339-2.933.565-3.268 2.017-.067.288-.085.58-.056.867l.069.694-.68-.157-2.58-.597a1.5 1.5 0 0 0-1.8 1.124l-.502 2.171Z"
      fill="#5561E6"
      stroke="#FFF"
    /></g
  ></svg
>
