<svg viewBox="0 0 86 84" xmlns="http://www.w3.org/2000/svg"
  ><g fill="none" fill-rule="evenodd"
    ><path fill="#DF3D6D" d="M.542.79 14.707 18.9.541 27.941z" /><path
      d="M28.52 36.449 14.514 18.583.542 27.942c4.735 1.776 8.83 3.19 12.285 4.24 3.456 1.05 8.687 2.473 15.694 4.267Z"
      fill="#3DD6DF"
    /><path
      d="m14.452 18.629 11.787-8.524C21.386 8.02 17.44 6.427 14.4 5.325 11.36 4.223 6.74 2.71.54.789l13.911 17.84Z"
      fill="#DCDF3D"
    /><path
      d="M28.52 36.449c-.304-6.386-.61-11.332-.92-14.839-.31-3.507-.776-7.331-1.4-11.473l-11.748 8.492 14.069 17.82Z"
      fill="#3DDF69"
    /><path
      d="M.728 13.2c4.53 4.449 8.716 7.5 12.558 9.154 3.841 1.654 8.667 2.378 14.476 2.17l.759 11.925c-6.99-1.789-12.22-3.211-15.694-4.267-3.473-1.056-7.506-2.547-12.1-4.473-.262-3.46-.411-6.117-.446-7.973C.246 17.879.395 15.7.728 13.2Z"
      fill-opacity=".131"
      fill="#000"
    /><path
      d="M.542.79c.347 5.247.347 9.59 0 13.028-.348 3.438-.348 8.146 0 14.124l11.99 4.305 15.989 4.202c-.275-6.228-.566-11.116-.873-14.667-.308-3.55-.778-7.442-1.41-11.677-5.046-2.255-9.17-3.964-12.375-5.126C10.66 3.818 6.22 2.421.542.79ZM.542.789l27.979 35.66M26.239 10.105.542 27.709"
      stroke="#8B68C6"
      stroke-width=".5"
    /><path
      d="M28.364 36.207c10.094 14.75 8.4 23.627-5.082 26.63-12.087 2.856-12.768-11.124-9.814-13.566 2.955-2.443 13.762-1.726 11.898 20.366-1.308 9.673 2.615 12.106 9.376 13.145 6.76 1.039 26.174-1.18 32.496-16.75 6.321-15.57-4.824-23.436-11.486-20.099-6.662 3.338-8.15 14.228 6.804 20.099 9.97 3.914 17.544 1.882 22.723-6.096"
      stroke="#8B68C6"
      stroke-width=".5"
    /><path
      d="M46.763 51.836c-1.306.486 3.56 2.781 4.302 2.781.742 0 5.633.275 5.633-1.06 0-1.334-5.633.858-5.633.858-1.997-2.043-3.43-2.902-4.302-2.579ZM51.376 63.265c-.751 1.174 4.516.078 5.109-.368.592-.447 4.664-3.17 3.86-4.237-.803-1.066-3.982 4.075-3.982 4.075-2.824-.43-4.486-.253-4.987.53ZM56.665 40.591c-1.104-.85-.604 4.54-.21 5.17.393.63 2.883 4.875 4.015 4.167 1.132-.707-3.805-4.34-3.805-4.34.737-2.764.737-4.43 0-4.997Z"
      stroke="#8B68C6"
      stroke-width=".5"
    /></g
  ></svg
>
