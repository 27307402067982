<svg viewBox="0 0 50 50" xmlns="http://www.w3.org/2000/svg"
  ><g fill="none" fill-rule="evenodd"
    ><path
      d="M29.591 5.93c-8.87-2.021-13.331-.52-16.697 2.314C9.528 11.077 6.8 17.47 8.54 28.865 10.28 40.26 17.819 45 23.705 45c5.886 0 12.009-2.816 15.35-8.516 3.343-5.7 2.929-10.585 2.929-17.813 0-7.227-3.523-10.72-12.393-12.741Z"
      fill="#FFA156"
    /><circle fill="#FFAF6F" cx="25" cy="25" r="11" /><path
      stroke="#FFF"
      fill="#b55c3b"
      d="m25 18-13 5.34L25.491 27l12.978-3.66z"
    /><g transform="translate(18 23)" stroke="#FFF"
      ><path
        d="M.5 2.143V7.5h13V2.143l-.061-.096C12.906 1.215 10.18.5 7 .5S1.094 1.215.561 2.047L.5 2.143Z"
        fill="#c66b4a"
      /><ellipse fill="#b55c3b" cx="7" cy="8" rx="6.5" ry="1.5" /></g
    ><path stroke="#FFF" stroke-width=".5" d="M14 23.436v6.88" /><path
      d="M13.997 34.452c1.105-2.2 1.69-3.7 1.753-4.463.027-.621-.902-1.118-1.75-1.118-1.01 0-1.75.352-1.75 1.129 0 .686.584 2.186 1.747 4.452Z"
      stroke="#FFF"
      stroke-width=".5"
      fill="#EAAD6E"
    /><path
      fill="#E65591"
      d="m5.5 7.75-.773 1.128.038-1.367-1.288.458.834-1.083L3 6.5l1.311-.386-.834-1.083 1.288.458-.038-1.367L5.5 5.25l.773-1.128-.038 1.367 1.288-.458-.834 1.083L8 6.5l-1.311.386.834 1.083-1.288-.458.038 1.367z"
    /><path
      fill="#4EC0FA"
      d="m41.5 6.75-.773 1.128.038-1.367-1.288.458.834-1.083L39 5.5l1.311-.386-.834-1.083 1.288.458-.038-1.367.773 1.128.773-1.128-.038 1.367 1.288-.458-.834 1.083L44 5.5l-1.311.386.834 1.083-1.288-.458.038 1.367z"
    /><path
      fill="#5561E6"
      d="m45.433 16.08-.346 1.038-.272-1.06-.89.636.403-1.017-1.094-.008.924-.586-.88-.648 1.09.067-.33-1.042.843.697.346-1.038.272 1.06.89-.636-.403 1.016 1.093.008-.923.586.88.65-1.09-.07.33 1.044z"
    /><path
      fill="#FFC94F"
      d="m36.47 46.848-1.002.437.605-.91-1.068-.235 1.025-.382-.726-.818 1.053.294-.106-1.089.68.857.553-.942.046 1.092 1.002-.437-.605.91 1.068.235-1.025.382.726.818-1.053-.294.106 1.089-.68-.857-.553.942z"
    /></g
  ></svg
>
