<script>
  import { Link } from "svelte-navigator";
  import FooterBackground from "../assets/FooterBackground.svelte";

  /**
   * FIX: For larger screens
   */
</script>

<footer class="footer">
  <div class="footer-background">
    <FooterBackground />
  </div>

  <div class="footer-middle">
    <div class="footer-links">
      <Link class="footer-link" to="/">Home</Link>
      <Link class="footer-link" to="/company">Company</Link>
      <Link class="footer-link" to="/invest">Invest</Link>
    </div>
    <div class="footer-copyright">
      <p>STEAMius @2022, all rights reserved.</p>
    </div>
  </div>
</footer>

<style>
  .footer {
    display: flex;
    align-items: flex-end; /* Safari don't respect 'end' */
    justify-content: center;
    position: relative;
    height: 25vw;
    user-select: none;
    overflow: auto; /* Safari has a strange scrolling bug if we don't add this */
  }
  .footer-middle {
    position: relative;
    margin-bottom: 20px;
  }
  .footer-links {
    display: none;
    justify-content: center;
    align-items: center;
    gap: 40px;
    padding-bottom: 24px;
  }
  :global(.footer-link) {
    padding: 0 20px;
    font-size: 30px;
    font-family: wonderboys;
    text-decoration: none;
    color: #fff;
    transition: all 0.2s;
  }
  :global(.footer-link:hover) {
    transform: scale(1.25);
  }
  .footer-copyright {
    font-size: 10px;
    text-align: center;
    color: #243d13;
  }
  .footer-background {
    position: absolute;
    width: 100%;
  }
  /* https://stackoverflow.com/questions/22337292/spurious-margin-on-svg-element */
  :global(.footer-background svg) {
    vertical-align: bottom;
  }
  @media all and (min-width: 768px) {
    .footer-links {
      display: flex;
    }
  }
</style>
